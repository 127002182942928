import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react';

import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

function App() {

  const [day, setDay] = useState(1);
  const [period, setPeriod] = useState(1);

  let state = {
    "C101": "busy",
    "C103": "busy",
    "C106": "busy",
    "C108": "busy",
    "C109": "avaliable",
    "C110": "busy",
    "C111": "busy",
    "C113": "busy",
    "C114": "busy",
    "LIBA": "notinuse",
    "LIBB": "notinuse",
  }

  const [roomState, setRoomState] = useState(state)

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);


  const now = (e) => {
    console.log("test")
    console.log(Math.round((Date.now()-1640995200000)/1000/3600/24))
    console.log("week",Math.ceil((Date.now()-1640995200000)/1000/3600/24/7))
    console.log(Math.round((Date.now()-1640995200000)/1000/3600/24))
    const calweek = 2-(Math.ceil((Date.now()-1640995200000)/1000/3600/24/7)%2)
    const calday = Math.round((Date.now()-1640995200000)/1000/3600/24-2)%7+5*(1-Math.ceil((Date.now()-1640995200000)/1000/3600/24/7)%2)
    setDay(calday)
    const nowDate = new Date(Date.now())
    const nowHour = nowDate.getHours()
    const nowMin = nowDate.getMinutes()
    if (nowHour < 9 && nowMin < 40) {

    } else if (nowHour < 9 && nowMin < 40) {
      setPeriod(1)
    } else if (nowHour < 10 && nowMin < 40) {
      setPeriod(2)
    } else if (nowHour < 12 && nowMin < 0) {
      setPeriod(3)
    } else if (nowHour < 1 && nowMin < 0) {
      setPeriod(4)
    } else if (nowHour < 2 && nowMin < 45) {
      setPeriod(5)
    } else {
      setPeriod(6)
    }
  }

  const roomMatrixes = {
    "C101": [
    // 1,2,3,4,5,6,7,8,9,10
      [0,0,0,0,0,0,0,0,0,0], // Period 1
      [0,0,0,0,0,0,0,1,0,0], // Period 2
      [0,0,0,0,1,0,0,0,1,0], // Period 3
      [0,0,0,0,0,0,0,0,0,0], // Period 4
      [0,0,1,0,0,0,0,1,0,0], // Period 5
      [0,0,1,0,0,0,0,1,0,0], // Period 6      
    ],
    "C103": [
      // 1,2,3,4,5,6,7,8,9,10
        [0,0,0,0,1,0,0,1,0,0], // Period 1
        [1,0,0,0,0,0,1,0,0,0], // Period 2
        [0,0,0,0,1,0,0,0,0,0], // Period 3
        [0,0,0,0,0,0,0,0,0,0], // Period 4
        [0,0,1,0,0,1,0,1,0,0], // Period 5
        [0,0,1,1,0,0,0,1,0,0], // Period 6      
      ],
    "C106": [
      // 1,2,3,4,5,6,7,8,9,10
        [0,0,0,0,0,0,0,0,0,0], // Period 1
        [0,0,0,0,0,0,0,0,0,0], // Period 2
        [0,0,0,0,1,0,0,1,0,0], // Period 3
        [0,1,0,0,0,0,0,0,0,1], // Period 4
        [0,0,1,0,0,0,0,1,0,0], // Period 5
        [0,0,0,0,0,0,0,1,0,0], // Period 6      
      ],
    "C108": [
      // 1,2,3,4,5,6,7,8,9,10
        [0,0,0,0,0,0,0,0,0,0], // Period 1
        [0,0,0,0,0,0,0,1,0,0], // Period 2
        [1,0,0,0,1,0,0,1,0,0], // Period 3
        [0,0,0,0,0,0,0,0,0,0], // Period 4
        [0,1,1,0,0,0,0,1,0,0], // Period 5
        [0,0,1,0,0,0,1,1,0,0], // Period 6      
      ],
    "C110": [
      // 1,2,3,4,5,6,7,8,9,10
        [0,0,0,0,0,0,0,0,0,0], // Period 1
        [0,0,0,0,0,0,0,0,0,0], // Period 2
        [0,0,0,0,1,0,0,1,0,0], // Period 3
        [0,0,0,0,0,0,0,0,0,0], // Period 4
        [0,1,1,0,0,0,0,1,0,0], // Period 5
        [0,0,1,0,0,0,0,1,0,0], // Period 6      
      ],
    "C111": [
      // 1,2,3,4,5,6,7,8,9,10
        [0,0,0,0,0,0,0,1,0,0], // Period 1
        [0,0,0,0,0,0,0,1,0,0], // Period 2
        [0,0,0,0,1,0,0,1,0,0], // Period 3
        [0,0,0,0,0,0,0,0,0,0], // Period 4
        [0,1,1,0,0,0,0,1,0,0], // Period 5
        [1,0,1,0,0,0,0,1,0,0], // Period 6
      ],
    "C113": [
      // 1,2,3,4,5,6,7,8,9,10
        [1,0,0,0,0,0,0,0,0,0], // Period 1
        [1,0,0,0,0,0,0,0,0,0], // Period 2
        [0,0,0,0,0,0,0,0,0,0], // Period 3
        [0,0,0,0,0,0,0,0,0,0], // Period 4
        [0,0,0,0,0,0,0,0,0,0], // Period 5
        [1,0,0,0,0,0,0,0,0,0], // Period 6      
      ],
    "C114": [
      // 1,2,3,4,5,6,7,8,9,10
        [0,0,0,0,0,0,0,0,0,0], // Period 1
        [0,0,0,0,0,0,0,0,0,0], // Period 2
        [0,0,0,0,0,0,0,0,0,0], // Period 3
        [0,0,0,0,0,0,0,0,0,0], // Period 4
        [0,0,0,0,0,0,0,0,0,0], // Period 5
        [0,0,0,0,0,0,0,0,0,0], // Period 6      
      ],
    "LIBA": [
      // 1,2,3,4,5,6,7,8,9,10
        [0,0,0,0,0,0,0,0,0,0], // Period 1
        [0,0,0,0,0,0,0,0,0,0], // Period 2
        [0,0,0,0,0,0,0,0,0,0], // Period 3
        [0,0,0,0,0,0,0,0,0,0], // Period 4
        [0,0,0,0,0,0,0,0,0,0], // Period 5
        [0,0,0,0,0,0,0,0,0,0], // Period 6      
      ],
    "LIBB": [
        // 1,2,3,4,5,6,7,8,9,10
          [0,0,0,0,0,0,0,0,0,0], // Period 1
          [0,0,0,0,0,0,0,0,0,0], // Period 2
          [0,0,0,0,0,0,0,0,0,0], // Period 3
          [0,0,0,0,0,0,0,0,0,0], // Period 4
          [0,0,0,0,0,0,0,0,0,0], // Period 5
          [0,0,0,0,0,0,0,0,0,0], // Period 6      
      ],
  }

  const disabled = {
    "day":  {
      "a":false,
      "b":false,
      "c":false,
      "d":false,
      "e":false,
      "f":false,
      "g":true,
      "h":false,
      "i":false,
      "j":false,
    },
    "period":  {
      "a":false,
      "b":false,
      "c":true,
      "d":false,
      "e":false,
      "f":false,
    },
  }


  const rooms = [
    "C101",
    "C103",
    "C106",
    "C108",
    "C109",
    "C110",
    "C111",
    "C113",
    "C114",
    "LIBA",
    "LIBB"
  ]



  const getStates = async (e) => {
    for (const room of rooms) {
      const matrix = roomMatrixes[room]
      if (matrix !== undefined) {
        console.log(room)
        console.log(matrix)
        console.log(matrix[period-1][day-1])
        if (matrix[period-1][day-1]==1) {
          state[room] = "avaliable"
        } else if (matrix[period-1][day-1]===0) {
          state[room] = "busy"
        } else {
          state[room] = "notinuse"
        }
      }
    }
    console.log(state)
    setRoomState(state)
  }

  const clickHandler = async (e,type,n) => {
    if (type === "period") {
      setPeriod(n)
    }
    if (type === "day") {
      setDay(n)
    }
    getStates(e)
  }


  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Free Classroom Finder
        </h1>

      </header>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="500pt" height="500pt" viewBox="0 0 500 500" >
        <defs>
        </defs>
        <g transform="matrix(1 0 0 1 83.54 50.74)" id="JXnR15GtDXTEcSFs4WOZK"  >
        <path className={`App-rect ${roomState.C108}`} vectorEffect="non-scaling-stroke"  transform=" translate(-50, -30)" d="M 0 0 L 100 0 L 100 60 L 0 60 z" strokeLinecap="round" />
        <text>C108</text>
        </g>
        <g transform="matrix(1 0 0 1 253.29 50.74)" id="kI6WK_WnO5LroruGMxUbA"  >
        <path className={`App-rect ${roomState.C106}`} vectorEffect="non-scaling-stroke"  transform=" translate(-50, -30)" d="M 0 0 L 100 0 L 100 60 L 0 60 z" strokeLinecap="round" />
        <text>C106</text>
        </g>
        <g transform="matrix(1 0 0 1 83.54 200.74)" id="yCU8Q0oIYoBZK-9g2E-Zj"  >
        <path className={`App-rect ${roomState.C110}`} vectorEffect="non-scaling-stroke"  transform=" translate(-50, -30)" d="M 0 0 L 100 0 L 100 60 L 0 60 z" strokeLinecap="round" />
        <text>C110</text>
        </g>
        <g transform="matrix(0.7 0 0 1 168.41 50.74)" id="jJNVAU7pGYw9TV5OOmEpl"  >
        <path className={`App-rect notinuse`} vectorEffect="non-scaling-stroke"  transform=" translate(0, 0)" d="M -50 -30 L 50 -30 L 50 30 L -50 30 z" strokeLinecap="round" />
        <text></text>
        </g>
        <g transform="matrix(1 0 0 1 268.29 125.74)" id="EwU127Gku4Ov92r9ynaZR"  >
        <path className={`App-rect notinuse`} vectorEffect="non-scaling-stroke"  transform=" translate(-35, -45)" d="M 0 0 L 70 0 L 70 90 L 0 90 z" strokeLinecap="round" />
        <text></text>
        </g>
        <g transform="matrix(1 0 0 1 253.29 200.74)" id="_4cJpjPAPZGauOUv2Kdlw"  >
        <path className={`App-rect ${roomState.C103}`} vectorEffect="non-scaling-stroke"  transform=" translate(0, 0)" d="M -50 -30 L 50 -30 L 50 30 L -50 30 z" strokeLinecap="round" />
        <text>C103</text>
        </g>
        <g transform="matrix(1 0 0 0.54 68.54 105.04)" id="CFEz69W2UK3vPUcGMcmAl"  >
        <path className={`App-rect ${roomState.C109}`} vectorEffect="non-scaling-stroke"  transform=" translate(0, 0)" d="M -35 -45 L 35 -45 L 35 45 L -35 45 z" strokeLinecap="round" />
        <text></text>
        </g>
        <g transform="matrix(1 0 0 0.46 68.54 150.04)" id="Z1KkbNvCeWO6FsL1xXfPJ"  >
        <path className={`App-rect notinuse`} vectorEffect="non-scaling-stroke"  transform=" translate(0, 0)" d="M -35 -45 L 35 -45 L 35 45 L -35 45 z" strokeLinecap="round" />
        <text></text>
        </g>
        <g transform="matrix(1 0 0 1 83.54 260.74)" id="kEAQahoGXNKq2Bd3Af8TY"  >
        <path className={`App-rect ${roomState.C111}`} vectorEffect="non-scaling-stroke"  transform=" translate(-50, -30)" d="M 0 0 L 100 0 L 100 60 L 0 60 z" strokeLinecap="round" />
        <text>C111</text>
        </g>
        <g transform="matrix(1 0 0 1 253.29 260.74)" id="49dvWywEOEJtgUU_roxsH"  >
        <path className={`App-rect ${roomState.C101}`} vectorEffect="non-scaling-stroke"  transform=" translate(-50, -30)" d="M 0 0 L 100 0 L 100 60 L 0 60 z" strokeLinecap="round" />
        <text>C101</text>
        </g>
        <g transform="matrix(1 0 0 1 158.54 374.85)" id="iJP5D_FdKV2PRe8ccfErY"  >
        <path className={`App-rect ${roomState.C113}`} vectorEffect="non-scaling-stroke"  transform=" translate(-25, -30)" d="M 0 0 L 50 0 L 50 60 L 0 60 z" strokeLinecap="round" />
        <text>C113</text>
        </g>
        <g transform="matrix(1 0 0 1 208.54 374.85)" id="YbiIOh68MyIjEX8wFwRNu"  >
        <path className={`App-rect ${roomState.C114}`} vectorEffect="non-scaling-stroke"  transform=" translate(-25, -30)" d="M 0 0 L 50 0 L 50 60 L 0 60 z" strokeLinecap="round" />
        <text>C114</text>
        </g>
        <g transform="matrix(1 0 0 1 418.11 233.06)" id="MI-anhXrmgLExSNdXOKmx"  >
        <path className={`App-rect ${roomState.LIBB}`} vectorEffect="non-scaling-stroke"  transform=" translate(-40, -40)" d="M 0 0 L 80 0 L 80 80 L 0 80 z" strokeLinecap="round" />
        <text>LIBB</text>
        </g>
        <g transform="matrix(1 0 0 1 418.11 125.74)" id="s7rvujciLbbVVP1rQZ0vP"  >
        <path className={`App-rect ${roomState.LIBA}`} vectorEffect="non-scaling-stroke"  transform=" translate(-40, -40)" d="M 0 0 L 80 0 L 80 80 L 0 80 z" strokeLinecap="round" />
        <text>LIBA</text>
        </g>
      </svg>
      <div className="centre-buttons">
        <ButtonToolbar aria-label="Toolbar with button groups">
          <ButtonGroup className="me-2" aria-label="First group" size="lg">
            <Button variant="info" disabled={true}>Day</Button>
            <Button variant="info" onClick={e => clickHandler(e,"day",1)} disabled={day === 1}>1</Button>
            <Button variant="info" onClick={e => clickHandler(e,"day",2)} disabled={day === 2}>2</Button>
            <Button variant="info" onClick={e => clickHandler(e,"day",3)} disabled={day === 3}>3</Button>
            <Button variant="info" onClick={e => clickHandler(e,"day",4)} disabled={day === 4}>4</Button>
            <Button variant="info" onClick={e => clickHandler(e,"day",5)} disabled={day === 5}>5</Button>
            <Button variant="info" onClick={e => clickHandler(e,"day",6)} disabled={day === 6}>6</Button>
            <Button variant="info" onClick={e => clickHandler(e,"day",7)} disabled={day === 7}>7</Button>
            <Button variant="info" onClick={e => clickHandler(e,"day",8)} disabled={day === 8}>8</Button>
            <Button variant="info" onClick={e => clickHandler(e,"day",9)} disabled={day === 9}>9</Button>
            <Button variant="info" onClick={e => clickHandler(e,"day",10)} disabled={day === 10}>10</Button>
          </ButtonGroup>
          <ButtonGroup className="me-2" aria-label="Second group" size="lg">
            <Button variant="info" disabled={true}>Period</Button>
              <Button variant="info" onClick={e => clickHandler(e,"period",1)} disabled={period === 1}>1</Button>
              <Button variant="info" onClick={e => clickHandler(e,"period",2)} disabled={period === 2}>2</Button>
              <Button variant="info" onClick={e => clickHandler(e,"period",3)} disabled={period === 3}>3</Button>
              <Button variant="info" onClick={e => clickHandler(e,"period",4)} disabled={period === 4}>4</Button>
              <Button variant="info" onClick={e => clickHandler(e,"period",5)} disabled={period === 5}>5</Button>
              <Button variant="info" onClick={e => clickHandler(e,"period",6)} disabled={period === 6}>6</Button>
          </ButtonGroup>
          <ButtonGroup className="me-2" aria-label="Third group" size="lg">
            <Button variant="primary" onClick={now}>Now</Button>
          </ButtonGroup>

        </ButtonToolbar>
      </div>
    </div>
  );
}

export default App;
